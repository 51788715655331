/* istanbul ignore file: test forms */
import {classes} from './FormLoader.st.css';
import React from 'react';

export const FormLoader = () => {
  return (
    <div className={classes.root}>
      <div className={classes.first}></div>
      <div className={classes.second}></div>
      <div className={classes.third}></div>
    </div>
  );
};
