import React, {useState} from 'react';
import {classes} from './CodeInputSection.st.css';
import {TextButton, TextButtonPriority, Text} from 'wix-ui-tpa';
import {CodeInput} from './CodeInput/CodeInput';

export interface CodeInputSectionProps {
  dataHook: string;
  enterACodeButtonText: string;
  onCodeInputOpen?: () => void;
  applyCodeText: string;
  removeCodeText: string;
  codeValue?: string;
  onApplyCode: (code: string) => void;
  onRemoveCode: () => void;
  error?: string;
  icon?: React.ReactElement;
  placeholder?: string;
}

export enum CodeInputSectionDataHooks {
  EnterACodeButton = 'CodeInputSection.EnterACodeButton',
  InputSection = 'CodeInputSection.InputSection',
}

export const CodeInputSection = ({
  dataHook,
  enterACodeButtonText,
  onCodeInputOpen,
  applyCodeText,
  removeCodeText,
  codeValue,
  onApplyCode,
  onRemoveCode,
  error,
  icon,
  placeholder,
}: CodeInputSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(!codeValue);

  return (
    <div data-hook={dataHook}>
      <TextButton
        className={classes.textButton}
        data-hook={CodeInputSectionDataHooks.EnterACodeButton}
        priority={TextButtonPriority.primary}
        prefixIcon={icon}
        onClick={() => {
          isCollapsed && onCodeInputOpen?.();
          setIsCollapsed(!isCollapsed);
        }}>
        <Text>{enterACodeButtonText}</Text>
      </TextButton>
      {!isCollapsed && (
        <div className={classes.codeInput}>
          <CodeInput
            dataHook={CodeInputSectionDataHooks.InputSection}
            applyText={applyCodeText}
            removeText={removeCodeText}
            appliedValue={codeValue}
            onApply={onApplyCode}
            onRemove={onRemoveCode}
            error={error}
            placeholder={placeholder}
          />
        </div>
      )}
    </div>
  );
};
