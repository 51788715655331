import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {Text} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes} from './ShippingDetails.st.css';
import {ZipCode} from '../ZipCode/ZipCode';
import {SubdivisionSelector} from '../SubdivisionSelector/SubdivisionSelector';
import {ContactAndAddressSummary} from '../../ContactAndAddressSummary/ContactAndAddressSummary';

export enum ShippingDetailsDataHook {
  root = 'ShippingDetails.root',
  title = 'ShippingDetails.title',
  contactAndAddressSummary = 'ShippingDetails.contactAndAddressSummary',
}

export const ShippingDetails = () => {
  const {
    checkoutStore: {checkout, shouldRequireZipCode, shouldShowSubdivisionSelector, isFastFlowWithPickupFlow},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  const shouldShowShippingOverBilling = checkout.hasShippableItems && !isFastFlowWithPickupFlow;
  const {contact, address} =
    (shouldShowShippingOverBilling ? checkout.shippingDestination : checkout.billingInfo) ?? {};

  return (
    <div data-hook={ShippingDetailsDataHook.root} className={classes.root}>
      <Text tagName={'h3'} className={classes.title} data-hook={ShippingDetailsDataHook.title}>
        {shouldShowShippingOverBilling
          ? localeKeys.checkout.place_order_fast_flow.shipping_details_title()
          : localeKeys.checkout.place_order_fast_flow.billing_details_title()}
      </Text>
      <ContactAndAddressSummary
        contact={contact}
        address={address}
        email={checkout.buyerInfo.email}
        customField={checkout.customField}
      />
      {shouldRequireZipCode && <ZipCode />}
      {shouldShowSubdivisionSelector && <SubdivisionSelector />}
    </div>
  );
};
