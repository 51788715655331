import React from 'react';
import {SectionNotification} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes} from '../DeliveryMethodPicker.st.css';
import {DeliveryMethodFastFlowDataHook} from '../../PlaceOrderFastFlow/DeliveryMethodFastFlow/DeliveryMethodFastFlow';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';

export const UnsupportedShippingDestinationError = () => {
  const localeKeys = useLocaleKeys();

  return (
    <SectionNotification
      data-hook={DeliveryMethodFastFlowDataHook.noShippingMessage}
      className={classes.text}
      type="error">
      <SectionNotification.Icon icon={<ErrorIcon />} />
      <SectionNotification.Text>
        {localeKeys.checkout.deliveryMethod.error.unsupportedRegion()}
      </SectionNotification.Text>
    </SectionNotification>
  );
};
