import React, {useEffect, useState} from 'react';
import {Button, Dialog, Text, TextTypography} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {hasPickupOption} from '../../../domain/utils/bi.util';
import {getErrorPropsFromCode} from '../../../domain/utils/errors';
import {classes} from './ErrorDialog.st.css';

export enum ErrorDialogDataHooks {
  Root = 'ErrorDialogDataHooks.Root',
  Title = 'ErrorDialogDataHooks.Title',
  Description = 'ErrorDialogDataHooks.Description',
  Button = 'ErrorDialogDataHooks.Button',
}

export const ErrorDialog = () => {
  const {
    checkoutStore: {placeOrderError, onErrorDialogOpened, onErrorDialogClosed, checkout},
    navigationStore: {clickOnBackToSite, clickOnReturnToCart},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  const [error] = useState(checkout.errors.noItemsError || placeOrderError);
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  useEffect(() => {
    onErrorDialogOpened();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDialogOnClose = () => {
    onErrorDialogClosed();
    setIsDialogOpen(false);
  };

  const handleClickOnReturnToCart = () => {
    void clickOnReturnToCart();
  };

  const errorToDisplay = getErrorPropsFromCode(
    error,
    localeKeys,
    {
      clickOnBackToSite,
      handleClickOnReturnToCart,
      handleDialogOnClose,
    },
    hasPickupOption(checkout),
    Boolean(checkout.cartId)
  );

  return (
    <Dialog data-hook={ErrorDialogDataHooks.Root} isOpen={isDialogOpen} onClose={handleDialogOnClose}>
      <div className={classes.root}>
        <div className={classes.content}>
          <Text data-hook={ErrorDialogDataHooks.Title} typography={TextTypography.largeTitle}>
            {errorToDisplay.title}
          </Text>
          <Text data-hook={ErrorDialogDataHooks.Description} typography={TextTypography.runningText}>
            {errorToDisplay.description}
          </Text>
        </div>
        <Button
          data-hook={ErrorDialogDataHooks.Button}
          className={classes.button}
          onClick={() => errorToDisplay.action.fn()}
          upgrade>
          {errorToDisplay.action.name}
        </Button>
      </div>
    </Dialog>
  );
};
