import React from 'react';
import {Card, Text, TextButton} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {Trans} from 'react-i18next';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './MemberInfoBox.st.css';

export enum MemberInfoBoxDataHook {
  root = 'MemberInfoBox.root',
  content = 'MemberInfoBoxDataHook.content',
  loginTextButton = 'MemberInfoBoxDataHook.loginTextButton',
}
export const MemberInfoBox = () => {
  return (
    <Card data-hook={MemberInfoBoxDataHook.root} className={classes.card}>
      <Card.Container>
        <MemberInfoBoxText />
      </Card.Container>
    </Card>
  );
};

const MemberInfoBoxText = () => {
  const {t, i18n} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {onLogin} = useControllerProps().navigationStore;

  return (
    <Text data-hook={MemberInfoBoxDataHook.content} className={classes.content}>
      <Trans
        i18n={i18n}
        t={t}
        i18nKey={localeKeys.checkout.membersInfoBox.login()}
        components={{
          1: (
            <TextButton
              key={'textButton'}
              data-hook={MemberInfoBoxDataHook.loginTextButton}
              className={classes.loginTextButton}
              onClick={() => onLogin()}></TextButton>
          ),
        }}
      />
    </Text>
  );
};
