import React, {useState, useEffect} from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader, ButtonSize} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './PlaceOrderButton.st.css';
import {usePaymentsApi} from '../WithPaymentsApi/WithPaymentsApi';
import {ICashierPaymentsApi} from '@wix/cashier-payments-widget';
import {useFunctionResultObservation} from '@wix/function-result-observation';

export const PlaceOrderButtonDataHook = 'place-order-button';

export interface PlaceOrderButtonProps {
  isMobile: boolean;
}

export const PlaceOrderButton = ({isMobile}: PlaceOrderButtonProps) => {
  const localeKeys = useLocaleKeys();
  const {paymentsApi} = usePaymentsApi();
  const {
    navigationStore: {navigateToThankYouPage},
    checkoutStore,
    deliveryMethodStore: {beforePlaceOrderClicked},
    checkboxesStore: {areCheckoutCheckboxesValid, isSubscriptionCheckboxChecked, setWasFormSubmitted},
  } = useControllerProps();
  const {isPlaceOrderButtonDisabled, placeOrderError} = checkoutStore;
  const [successButtonState, setSuccessButtonState] = useState(StatesButtonStates.IDLE);
  const {withObservation} = useFunctionResultObservation();
  const clickPlaceOrderButton = withObservation(checkoutStore, 'clickPlaceOrderButton');

  const handleSubmit = async () => {
    if (!areCheckoutCheckboxesValid) {
      setWasFormSubmitted(true);
      return;
    }

    setSuccessButtonState(StatesButtonStates.IN_PROGRESS);
    beforePlaceOrderClicked();

    const paymentResponse = await clickPlaceOrderButton(
      isSubscriptionCheckboxChecked,
      await getPaymentsDetailsId(paymentsApi)
    );

    if (paymentsApi && paymentResponse?.paymentResponseToken) {
      await paymentsApi.continuePayment?.(paymentResponse.paymentResponseToken);
    }

    if (paymentResponse?.orderId) {
      void navigateToThankYouPage(paymentResponse.orderId);
    }
  };

  useEffect(() => {
    if (placeOrderError) {
      setSuccessButtonState(StatesButtonStates.IDLE);
    }
  }, [placeOrderError]);

  return (
    <StatesButton
      className={isMobile ? classes.placeOrderButtonMobile : classes.placeOrderButton}
      data-hook={PlaceOrderButtonDataHook}
      disabled={isPlaceOrderButtonDisabled}
      upgrade={true}
      size={ButtonSize.medium}
      onClick={() => {
        void handleSubmit();
      }}
      idleContent={localeKeys.checkout.place_order.place_order_button()}
      state={successButtonState}
      inProgressContent={<ThreeDotsLoader className={classes.threeDotButton} />}
    />
  );
};

async function getPaymentsDetailsId(paymentsApi?: ICashierPaymentsApi): Promise<string | undefined> {
  if (!paymentsApi) {
    return;
  }
  const initializeResponse = await paymentsApi?.initializePayment?.();
  return initializeResponse?.detailsId;
}
