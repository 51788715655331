import {ITrackEventName, ITrackEventParams} from '@wix/native-components-infra';

const enum PaymentMethodType {
  PAYPAL = 'paypal',
}

const enum EventAction {
  AgreeToTerms = 'Agree To Terms',
}

const origin = 'Stores';
const eventCategory = 'Enhanced Ecommerce - Stores';

export const FastFlowAnalyticsEventParams = ['AddPaymentInfo', {option: PaymentMethodType.PAYPAL}] as [
  ITrackEventName,
  ITrackEventParams
];

export const AgreeToTermsAnalyticsEventParams = [
  'CustomEvent',
  {
    eventCategory,
    action: EventAction.AgreeToTerms,
    eventLabel: origin,
  },
] as [ITrackEventName, Record<string, any>];
