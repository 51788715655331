import React, {useState} from 'react';
import {Button, ButtonPriority, Text, TextField} from 'wix-ui-tpa';
import {classes, st} from './InputWithApply.st.css';

export enum InputWithApplyDataHook {
  label = 'InputWithApply.label',
  textField = 'InputWithApply.textField',
  applyButton = 'InputWithApply.applyButton',
}

export interface InputWithApplyProps {
  dataHook: string;
  title: string;
  buttonText: string;
  onApply: (inputValue: string) => void;
  validateInput: (inputValue: string) => boolean;
  errorMessage: string;
  className?: string;
  maxLength?: number;
  showCharCount?: boolean;
}

export const InputWithApply = ({
  dataHook,
  className,
  title,
  onApply,
  buttonText,
  validateInput,
  errorMessage,
  maxLength,
  showCharCount,
}: InputWithApplyProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isInputValid, setIsInputValid] = useState(true);

  const onApplyClicked = (): void => {
    setIsInputValid(validateInput(inputValue));
    onApply(inputValue);
  };

  return (
    <div className={st(classes.root, className)} data-hook={dataHook}>
      <div className={classes.labelContainer}>
        <Text className={classes.label} data-hook={InputWithApplyDataHook.label}>
          {title}
        </Text>
      </div>
      <div className={classes.inputAndButton}>
        <TextField
          className={classes.textField}
          data-hook={InputWithApplyDataHook.textField}
          value={inputValue}
          error={!isInputValid}
          errorMessage={errorMessage}
          newErrorMessage={true}
          onChange={(event) => setInputValue(event.target.value)}
          maxLength={maxLength}
          showCharCount={showCharCount}
        />
        <Button
          priority={ButtonPriority.basicSecondary}
          className={classes.button}
          data-hook={InputWithApplyDataHook.applyButton}
          onClick={onApplyClicked}
          upgrade={true}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
