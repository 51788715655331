import * as React from 'react';
import {classes, st} from './ItemPrice.st.css';
import {Text, TextPriority} from 'wix-ui-tpa';
import {LineItemDataHooks} from '../LineItem';

export interface PriceProps {
  shortPrice: boolean;
  formattedPrice: string;
  priceAriaLabel: string;
  formattedComparePrice?: string;
  comparePriceAriaLabel?: string;
}

export class ItemPrice extends React.PureComponent<PriceProps> {
  public render() {
    const {shortPrice, formattedPrice, priceAriaLabel, formattedComparePrice, comparePriceAriaLabel} = this.props;

    return (
      <div className={st(classes.root, shortPrice ? classes.shortPrice : undefined)}>
        {formattedComparePrice && (
          <Text
            priority={TextPriority.secondary}
            className={classes.price}
            data-hook={LineItemDataHooks.ComparePrice}
            aria-label={comparePriceAriaLabel}>
            <del>{formattedComparePrice}</del>
          </Text>
        )}
        <Text className={classes.price} data-hook={LineItemDataHooks.Price} aria-label={priceAriaLabel}>
          {formattedPrice}
        </Text>
      </div>
    );
  }
}
