import {classes} from './Layout.st.css';
import {MainLoader} from './MainLoader/MainLoader';
import {AsideLoader} from './AsideLoader/AsideLoader';
import React, {ReactNode} from 'react';

export interface LayoutProps {
  isLoading: boolean;
  failedToFetch?: boolean;
  Header: () => ReactNode;
  Aside: () => ReactNode;
  Footer: () => ReactNode;
  children: ReactNode;
  dataHook: string;
  dir: string;
}

export enum LayoutDataHooks {
  error = 'LayoutDataHooks.error',
}

export const Layout = ({dataHook, dir, isLoading, failedToFetch, Header, Aside, Footer, children}: LayoutProps) => {
  return (
    <div data-hook={dataHook} className={classes.root} dir={dir}>
      <header className={classes.header}>{!isLoading && Header()}</header>
      <div className={classes.container}>
        <section className={classes.main}>{isLoading ? <MainLoader /> : children}</section>
        <aside className={classes.aside}>{isLoading ? <AsideLoader /> : Aside()}</aside>
      </div>
      {!isLoading && Footer()}
      {/* TODO: get UX and error message */}
      {failedToFetch && (
        <div className={classes.error} data-hook={LayoutDataHooks.error}>
          Failed to fetch checkout!
        </div>
      )}
    </div>
  );
};
