import React, {useMemo} from 'react';
import {StepHeader} from './StepHeader';
import {DynamicStep, DynamicStepComponentProps} from './DynamicStep';
import {StepState} from '../StepState';

export interface StepProps {
  index: number;
  title: string;
  dataHook: string;
  collapsedComponent?: () => JSX.Element;
  openComponent: () => JSX.Element;
  emptyComponent?: () => JSX.Element;
}

export enum StepDataHooks {
  header = 'StepDataHooks.header',
}

export const Step = ({
  index,
  title,
  dataHook,
  openComponent: OpenComponent,
  collapsedComponent: CollapsedComponent,
  emptyComponent: EmptyComponent,
}: StepProps) => {
  const StepInternal = useMemo(
    () =>
      /* eslint-disable-next-line react/display-name */
      ({stepState, openStep}: DynamicStepComponentProps) => {
        return (
          <>
            <StepHeader stepState={stepState} label={title} onEdit={openStep} dataHook={StepDataHooks.header} />
            {stepState === StepState.EMPTY && EmptyComponent && <EmptyComponent />}
            {stepState === StepState.OPEN && <OpenComponent />}
            {stepState === StepState.COLLAPSED && CollapsedComponent && <CollapsedComponent />}
          </>
        );
      },
    [EmptyComponent, CollapsedComponent, OpenComponent, title]
  );

  return <DynamicStep index={index} dataHook={dataHook} component={StepInternal} />;
};
