import * as React from 'react';
import {ISantaProps} from '@wix/wixstores-client-storefront-sdk';
import {checkoutTheme} from './checkoutTheme';

export const withCheckoutThemeOverride = <P extends ISantaProps>(WrappedComponent: React.ComponentType<P>) => {
  // eslint-disable-next-line react/display-name
  return (props: P) => {
    const {style} = props.host;
    const host = {
      ...props.host,
      style: {
        ...style,
        ...checkoutTheme,
      },
    };
    return <WrappedComponent {...props} host={host} />;
  };
};
