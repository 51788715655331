import {createContext, useContext, useEffect} from 'react';
import {StepState} from './StepState';
import {ActiveStep, StepId} from '../types';

interface StepContextType {
  stepIds: StepId[];
  openStep: (index: number, name: StepId) => void;
  onStepOpened: (name: StepId) => void;
  activeStep: ActiveStep;
}
export const StepsContext = createContext({} as StepContextType);

export function useStepData(currentStepIndex: number) {
  const {stepIds, openStep, activeStep, onStepOpened} = useContext(StepsContext);
  const isLastStep = currentStepIndex === stepIds.length - 1;
  const stepId = stepIds[currentStepIndex];
  const stepState = getStepState({currentStepIndex, activeStepIndex: activeStep.stepIndex, isLastStep});

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        onStepOpened(stepId);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [stepState]
  );

  return {
    stepState,
    openStep: () => openStep(currentStepIndex, stepIds[currentStepIndex]),
    isLastStep,
  };
}

function getStepState({
  currentStepIndex,
  activeStepIndex,
  isLastStep,
}: {
  currentStepIndex: number;
  activeStepIndex: number;
  isLastStep: boolean;
}) {
  if (activeStepIndex < currentStepIndex) {
    return StepState.EMPTY;
  }

  return activeStepIndex === currentStepIndex || isLastStep ? StepState.OPEN : StepState.COLLAPSED;
}
