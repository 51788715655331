export const cashierMandatoryFieldPlaceholder = {
  issuerBank: '-',
  holderId: '-',
  email: '-',
  firstName: '-',
  lastName: '-',
  street: '-',
  houseNumber: '-',
  address: '-',
  city: '-',
  countryCode: '-',
  state: '-',
  zipCode: '-',
  phone: '-',
  fake: '-',
};
