import React from 'react';
import {Text} from 'wix-ui-tpa';
import {Checkboxes} from '../../../../Checkboxes/Checkboxes';
import {PlaceOrderButton} from '../../../../PlaceOrderButton/PlaceOrderButton';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {ErrorDialog} from '../../../../ErrorDialog/ErrorDialog';
import {classes} from './PlaceOrderOpen.st.css';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {PlaceOrderDataHook} from '../PlaceOrderStep';

export const PlaceOrderOpen = () => {
  const {
    checkoutStore: {placeOrderError, checkout},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  return (
    <div className={classes.root} data-hook={PlaceOrderDataHook.open}>
      <div className={classes.subtitle}>
        <Text data-hook={PlaceOrderDataHook.subtitle}>{localeKeys.checkout.place_order.description()}</Text>
      </div>
      <Checkboxes />
      <PlaceOrderButton isMobile={false} />
      {(checkout.errors.noItemsError || placeOrderError) && <ErrorDialog />}
    </div>
  );
};
