/* istanbul ignore file: test forms */
import {FORMS_TEMPLATE_IDS} from '../constants';
import {FormValues, FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {FormProps} from './ContactForm';
import {useControllerProps} from '../Widget/ControllerContext';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';

export const AdditionalInfoForm = ({formRef, formValues, setFormValues, formErrors, setFormErrors}: FormProps) => {
  const {i18n} = useTranslation();
  const {
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={{
        additional_info_1: {
          label: checkoutSettings.customField.title,
          required: checkoutSettings.customField.mandatory,
        },
      }}
      formId={FORMS_TEMPLATE_IDS.ADDITIONAL_INFO}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
    />
  );
};

export const getAdditionalInfoFormInitialState = (customField?: CustomFieldModel) => ({
  additional_info_1: customField?.value ?? '',
});

export const getCustomFieldFromAdditionalInfoFormValues = (additionalInfoFormValues: FormValues): string =>
  additionalInfoFormValues.additional_info_1 as string;
