import {Dropdown, Text} from 'wix-ui-tpa';
import React from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';

export enum BillingAddressDropdownDataHook {
  root = 'BillingAddressDropdown.root',
  title = 'BillingAddressDropdown.title',
  dropdown = 'BillingAddressDropdown.dropdown',
}

export const BillingAddressDropdown = () => {
  const localeKeys = useLocaleKeys();

  return (
    <div data-hook={BillingAddressDropdownDataHook.root}>
      <Text data-hook={BillingAddressDropdownDataHook.title}>
        {localeKeys.chooseAddressModal.billing_address_title()}
      </Text>
      <Dropdown data-hook={BillingAddressDropdownDataHook.dropdown} upgrade={true} />
    </div>
  );
};
