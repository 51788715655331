import React from 'react';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {CustomerDetailsStepDataHook} from '../CustomerDetailsStep';
import {PickupInfoBox} from '../../../../PickupInfoBox/PickupInfoBox';
import {classes} from './CustomerDetailsCollapsed.st.css';

export const CustomerDetailsCollapsed = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();

  return (
    <div data-hook={CustomerDetailsStepDataHook.collapsed} className={classes.root}>
      <ContactAndAddressSummary
        contact={checkout.shippingDestination?.contact}
        email={checkout.buyerInfo.email}
        customField={checkout.customField}
      />
      {checkout.selectedShippingOption?.pickupInfo && (
        <PickupInfoBox pickupInfo={checkout.selectedShippingOption.pickupInfo} />
      )}
    </div>
  );
};
