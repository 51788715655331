import {classes} from './AsideLoader.st.css';
import React from 'react';
import {Spinner} from 'wix-ui-tpa';

export const AsideLoader = () => {
  return (
    <div className={classes.root}>
      <Spinner />
    </div>
  );
};
