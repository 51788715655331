import React from 'react';
import {classes} from './Step.st.css';
import {Divider} from 'wix-ui-tpa';
import {StepState} from '../StepState';
import {useStepData} from '../useStepData';

export interface DynamicStepComponentProps {
  stepState: StepState;
  openStep: () => void;
}

export enum DynamicStepDataHooks {
  divider = 'DynamicStepDataHooks.divider',
}

export interface DynamicStepProps {
  index: number;
  dataHook: string;
  component: (props: DynamicStepComponentProps) => JSX.Element;
}

export const DynamicStep = ({index, dataHook, component: Component}: DynamicStepProps) => {
  const {stepState, isLastStep, openStep} = useStepData(index);

  const shouldShowDivider = stepState !== StepState.OPEN && !isLastStep;

  return (
    <div data-hook={dataHook} className={classes.stepContainer}>
      <Component stepState={stepState} openStep={openStep} />
      {shouldShowDivider && (
        <Divider className={classes.stepDivider} data-hook={DynamicStepDataHooks.divider} direction="horizontal" />
      )}
    </div>
  );
};
