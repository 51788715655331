import {StyleParamType, createStylesParams} from '@wix/tpa-settings';

export type IStylesParams = {
  checkout_showCoupon: StyleParamType.Boolean;
};

export default createStylesParams<IStylesParams>({
  checkout_showCoupon: {
    type: StyleParamType.Boolean,
    getDefaultValue: /* istanbul ignore next */ () => true,
  },
});
