import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {getCountryFromLocaleDataset} from '../../../../domain/utils/localeDataset.util';
import {Country} from '@wix/wixstores-locale-dataset-adapter';
import {AddressModel} from '../../../../domain/models/Address.model';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {classes} from './SubdivisionSelector.st.css';

export enum SubdivisionSelectorDataHook {
  dropdown = 'SubdivisionSelector.dropdown',
}
export const SubdivisionSelector = () => {
  const localeKeys = useLocaleKeys();
  const {t} = useTranslation();
  const {
    checkoutStore: {checkout, setSubdivision},
  } = useControllerProps();
  const checkoutInfo = checkout.hasShippableItems ? checkout.shippingDestination : checkout.billingInfo;

  const address = checkoutInfo?.address as AddressModel;
  const countryOptions: Country = getCountryFromLocaleDataset(address?.country as string);
  const subdivisionsOptions = countryOptions?.subdivisions?.list.map((subdivision) => ({
    id: `${countryOptions.shortKey!}-${subdivision.key}`,
    value: t(subdivision.displayName),
    isSelectable: true,
  }));

  const onSubdivisionSelect = (selectedOption: DropdownOptionProps) => {
    void setSubdivision(selectedOption.id as string);
  };

  return (
    <Dropdown
      data-hook={SubdivisionSelectorDataHook.dropdown}
      className={classes.subdivision}
      options={subdivisionsOptions}
      upgrade={true}
      onChange={onSubdivisionSelect}
      label={localeKeys.checkout.place_order_fast_flow.region_select_title({
        subdivision: t(countryOptions?.subdivisions?.displayName ?? /* istanbul ignore next */ ''),
      })}
    />
  );
};
