import React, {Children, ReactElement} from 'react';
import {ActiveStep, StepId} from '../types';
import {StepsContext} from './useStepData';

export enum StepsManagerDataHook {
  root = 'StepsManager.root',
}

export interface StepsManagerProps {
  children: (React.ReactElement | null)[];
  activeStep: ActiveStep;
  openStep: (index: number, name: StepId) => void;
  onStepOpened: (name: StepId) => void;
}

export const StepsManager = ({children, openStep, activeStep, onStepOpened}: StepsManagerProps) => {
  const actualChildren = children.filter((child) => !!child) as React.ReactElement[];

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
  const stepIds = actualChildren.map((child: JSX.Element) => child.type.id);

  return (
    <StepsContext.Provider value={{openStep, stepIds, activeStep, onStepOpened}}>
      <div data-hook={StepsManagerDataHook.root}>
        {Children.map(actualChildren, (child: ReactElement, index) => React.cloneElement(child, {index}))}
      </div>
    </StepsContext.Provider>
  );
};
